.modal-retrospectiva {
  width: 820px;
  max-width: none;
}

.modal-retrospectiva .modal-header .close {
  margin-top: 3px;
  margin-right: 0px;
  padding: 0;
}

.modal-retrospectiva .modal-body img {
  width: 100%;
  cursor: pointer;
}

.retrospectiva-iframe-window {
  position: fixed;
  background: #3C44B1;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  transition: width .50s, height .50s, left .50s, top .50s;
}

.retrospectiva-iframe-window.frameOpen {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.retrospectiva-iframe-window iframe {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}

.retrospectiva-iframe-close {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  font-size: 24px;
  color: #13192F;
  background-color: #D9D9D9;
  border-radius: 5px;
  line-height: 27px;
  padding: 2px 8px;
  cursor: pointer;
  outline: none;
}
